/* eslint-disable react/no-array-index-key */
import React from 'react'
import Top from './blocks/Top'
import Image from './blocks/Image'
import Content from './blocks/Content'
import Products from './blocks/Products'
import Maps from './blocks/Maps'
import Contacts from './blocks/Contacts'

const Component = (data) => (
  <section className="blocks">
    {data.content === null ||
      data.content.map((item, i, arr) => (
        <div className="container">
          <Top item={item} key={i} arr={arr} />

          <Image item={item} key={i} arr={arr} />

          <Content item={item} key={i} arr={arr} />

          <Products item={item} key={i} arr={arr} />

          <Maps item={item} key={i} arr={arr} />

          <Contacts item={item} key={i} arr={arr} />
        </div>
      ))}
  </section>
)

export default Component
