import React from 'react'

const Component = ({ item, i, arr }) => (
  <>
    {item.internal.type === 'WordPressAcf_top' && (
    <div
      className={`row block-top justify-content-center ${arr.length >
        i && 'line-enabled'}`}
    >
      <div className="col-12 col-md-10 col-lg-9 heading">
        {item.underrubrik && (
          <h1 className="underrubrik">{item.underrubrik}</h1>
        )}
      </div>
      <div
        className="col-12 col-lg-11 ingress"
        dangerouslySetInnerHTML={{ __html: item.ingress }}
      />
      {arr.length > i && <div className="col-12 line" />}
    </div>
  )}
  </>
)

export default Component
