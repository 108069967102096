import React from 'react'
import GoogleMapReact from 'google-map-react'
import MarkerLogo from '../../../static/img/map-marker-alt.svg'

const isClient = typeof window !== 'undefined'

const options = {
  styles: [
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 36,
        },
        {
          color: '#333333',
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#ffffff',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#fefefe',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#fefefe',
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dedede',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f2f2f2',
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e9e9e9',
        },
        {
          lightness: 17,
        },
      ],
    },
  ],
};

const Component = ({ item, i, arr }) => (
  <>
    {item.internal.type === 'WordPressAcf_maps' && (
      <div className="row block-map">
        <div className="col-12 col-sm-6 map">
          {isClient && (
            <div className="map-container">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyB3In4n5AHIgzkYQ9aIa11QpqVPJLe6EB8',
                }}
                options={options}
                yesIWantToUseGoogleMapApiInternals
                defaultCenter={[item.map.lat, item.map.lng]}
                defaultZoom={11}
              >
                <div
                  className="marker"
                  lat={item.map.lat}
                  lng={item.map.lng}
                  style={{ width: '30px', height: '30px' }}
                >
                  <img src={MarkerLogo} /> 
                </div>
              </GoogleMapReact>
            </div>
          )}
        </div>
        <div className="col-12 col-sm-6">
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: item.content }}
          />
        </div>
      </div>
    )}
  </>
)

export default Component
