import React from 'react'

const Component = ({ item }) => (
  <>
    {item.internal.type === 'WordPressAcf_content' && (
      <div className="row block-content">
        <div className="col-12">
          {item.content && (
            <div
              className="content content-columns"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          )}
        </div>
      </div>
    )}
  </>
)

export default Component