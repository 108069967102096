import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Top from '../components/Top'
import Block from '../components/Block'

const Page = ({ data, pageContext }) => (
  <Layout type={pageContext.type} title={data.wordpressPage.title}>
    <Top data={data.wordpressPage.acf.topp} pageContext={pageContext} />
    <Block content={data.wordpressPage.acf.block_page} />
  </Layout>
)

export default Page

export const query = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      template
      slug
      acf {
        block_page {
          ... on WordPressAcf_top {
            internal {
              type
            }
            rubrik
            underrubrik
            ingress
          }
          ... on WordPressAcf_content {
            internal {
              type
            }
            content
          }
          ... on WordPressAcf_products {
            internal {
              type
            }
            product_category {
              show_animal
              dot_y
              dot_x
              rubrik
              products {
                product
              }
            }
          }
          ... on WordPressAcf_maps {
            internal {
              type
            }
            map {
              lat
              lng
            }
            content
          }
          ... on WordPressAcf_image {
            internal {
              type
            }
            rubrik
            text
            image_y
            image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 600
                    base64Width: 240
                    srcSetBreakpoints: [200, 340, 460]
                    quality: 85
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WordPressAcf_contacts {
            internal {
              type
            }
            contact {
              rubrik
              content
            }
          }
        }
        topp {
          rubrik
          underrubrik
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, base64Width: 400, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image_mobile {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 920, base64Width: 200, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
