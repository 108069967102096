import React from 'react'

const Component = ({ item }) => (
  <>
    {item.internal.type === 'WordPressAcf_contacts' && (
      <div className="row block-contacts">
        {item.contact.map(contact => (
          <div
            className={`col-6 col-sm-4 col-lg-3 contact ${
              !contact.rubrik ? 'align-self-end' : ''
            }`}
          >
            {contact.rubrik && <h4>{contact.rubrik}</h4>}
            {contact.content && (
              <div
                className={`info ${!contact.rubrik && 'align-self-end'}`}
                dangerouslySetInnerHTML={{ __html: contact.content }}
              />
            )}
          </div>
        ))}
      </div>
    )}
  </>
)

export default Component
