import React, { useRef, useState, useEffect } from 'react'
import Img from 'gatsby-image'
import { motion, useViewportScroll, useTransform } from 'framer-motion'

const Component = ({ imageX, imageY, image, alt }) => {
  const { scrollY } = useViewportScroll()
  const imageRef = useRef(null)
  const [imageTop, setImageTop] = useState(0)
  const [imageBot, setImageBot] = useState(0)

  useEffect(() => {
    const imageRect = imageRef.current.getBoundingClientRect()
    setImageTop((window.innerWidth > 991) ? imageRect.top - window.innerHeight : 0)
    setImageBot((window.innerWidth > 991) ? imageRect.bottom + window.innerHeight : 0)
  }, [])

  const x = useTransform(
    scrollY,
    [imageTop, imageBot],
    [imageX.start, imageX.end]
  )

  const y = useTransform(
    scrollY,
    [imageTop, imageBot],
    [imageY.start, imageY.end]
  )
  return (
    <div className="image-container">
      <motion.div
        className="image-wrap"
        ref={imageRef}
        style={{
          translateX: x,
          translateY: y,
        }}
      >
        <Img fluid={image} alt={alt} />
      </motion.div>
    </div>
  )
}

export default Component
