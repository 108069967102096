import React, { useRef, useState, useEffect } from 'react'
import Img from 'gatsby-image'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import Menu from './Menu'

const Mouse = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 404 532"
    style={{ width: '30px' }}
  >
    <path
      style={{
        fill: 'none',
        stroke: '#fff',
        strokeMiterlimit: 10,
        strokeWidth: '20px',
      }}
      d="M234,10H170A160,160,0,0,0,10,170V362A160,160,0,0,0,170,522h64A160,160,0,0,0,394,362V170A160,160,0,0,0,234,10Z"
    />
    <path
      style={{ fill: '#ae0f0a' }}
      d="M196.4971,325.4314l-95.8154-95.0385a7.7757,7.7757,0,0,1,0-11.0058L113.5,206.5686a7.7758,7.7758,0,0,1,11.0059,0L202,283.2856l77.4939-76.717a7.7758,7.7758,0,0,1,11.0059,0l12.8185,12.8185a7.7757,7.7757,0,0,1,0,11.0058l-95.8154,95.0385a7.7757,7.7757,0,0,1-11.0058,0Z"
    />
  </svg>
)

const Component = ({ data, pageContext }) => {
  const { scrollY } = useViewportScroll()
  const imageRef = useRef(null)
  const [imageBot, setImageBot] = useState(2400)

  useEffect(() => {
    const imageRect = imageRef.current.getBoundingClientRect()
    setImageBot( imageRect.bottom + window.innerHeight )
  }, [])

  const y = useTransform(
    scrollY,
    [0, imageBot],
    ['0vh', '15vh']
  )
  return (
    <section className={`top ${pageContext.type}`}>
      <Menu />

      <motion.div
        className="image-wrap"
        ref={imageRef}
        style={{
          translateY: y,
        }}
      >
        <Img
          fluid={data.image.localFile.childImageSharp.fluid}
          alt={data.image.alt_text}
          loading="eager"
          fadeIn={false}
          objectFit="cover"
          objectPosition="50% 50%"
          className={data.image_mobile ? 'd-none d-sm-block' : null}
        />
        {data.image_mobile && (
          <Img
            fluid={data.image_mobile.localFile.childImageSharp.fluid}
            alt={data.image_mobile.alt_text}
            loading="eager"
            objectFit="cover"
            objectPosition="50% 50%"
            className="d-sm-none"
          />
        )}
      </motion.div>

      {pageContext.type === 'frontpage' && (
        <div className="content">
          <div className="container position-relative h-100">
            <div className="row h-100 justify-content-end align-items-end align-items-md-center">
              <div className="col-12 text-center text-md-right">
                {data.rubrik && (
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: data.rubrik,
                    }}
                  />
                )}
                {data.underrubrik && (
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: data.underrubrik,
                    }}
                  />
                )}

                <div
                  className="mouse d-md-none pt-3 pb-3"
                  style={{
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  <Mouse />
                </div>
              </div>
            </div>
            <div
              className="mouse d-none d-md-block"
              style={{
                position: 'absolute',
                bottom: '15px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              <Mouse />
            </div>
          </div>
        </div>
      )}
    </section>
  )}

export default Component
