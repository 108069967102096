import React from 'react'
import Parallax from "../ParallaxImage"

const Component = ({ item }) => (
  <>
    {item.internal.type === 'WordPressAcf_image' && (
    <div className="row block-image align-items-center">
      <div
        className={`col-12 col-md-6 image align-self-start align-self-md-center order-1 ${
          item.image_y === 'left' ? 'order-md-1' : 'order-md-2'
        }`}
      >
        {item.image && (
          <Parallax image={item.image.localFile.childImageSharp.fluid} imageX={{start: 0, end: 0}} imageY={{start: -50, end: 0}} alt={item.image.alt_text} />
        )}
      </div>

      <div
        className={`col-12 col-md-6 content order-2 ${
          item.image_y === 'right' ? 'order-md-1' : 'order-md-2'
        }`}
      >
        {item.rubrik && <h3 className="rubrik">{item.rubrik}</h3>}
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: item.text }}
        />
      </div>
    </div>
  )}
  </>
)

export default Component
